import React from 'react';
import { Card, Col, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import withTrackingImpression from 'hocs/withTrackingImpression';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash-es';
import { trackingEvent } from '@redux/eventTracking/actions';
import { ExportIcon } from '@uikit/commons/SVGIcons';
import { AdsCardStyles, CallingActionStyle } from './styles';
import DestinationAction from './DestinationAction';
import AdsCardCover from './AdsCardCover';

const AdsCard = (props: AdsCardProps) => {
  const dispatch = useDispatch();
  const { heading, adsTitle, description, isParentApp, thumbnails, action } =
    props;

  const bottomRight = heading?.bottomRight?.[0];

  const handleClickCard = () => {
    const eventPayload: LoggingData | undefined = action?.loggingData;
    dispatch(trackingEvent(omit(eventPayload, '_typename')));
  };

  return (
    <>
      <DestinationAction bottomRight={bottomRight}>
        <AdsCardStyles className="h-full">
          <Card
            hoverable
            cover={
              <AdsCardCover
                thumbnails={thumbnails}
                heading={heading}
                fullWidth={false}
              />
            }
            className="bg-white w-full h-full rounded-12 position-relative"
            onClick={handleClickCard}
          >
            <CallingActionStyle isParentApp={isParentApp}>
              <Row align="middle" justify="space-between">
                <Col>
                  <strong className="size-12 fw-bold ">
                    {heading?.bottomLeft[0]?.value?.toUpperCase()}
                  </strong>
                </Col>
                <Col>
                  <DestinationAction bottomRight={bottomRight}>
                    <ExportIcon className="size-12" />
                  </DestinationAction>
                </Col>
              </Row>
            </CallingActionStyle>

            <Meta className="p-12" title={adsTitle} description={description} />
          </Card>
        </AdsCardStyles>
      </DestinationAction>
    </>
  );
};

export default withTrackingImpression(AdsCard);
