import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import JobCardCover from 'components/forJobSeekers/CardItems/JobCard/JobCardCover';
import { OrganizationDiscountCardStyles } from 'components/forJobSeekers/CardItems/OrganizationDiscountCard/styles';
import qs from 'qs';
import AddressSection from 'components/forJobSeekers/components/AddressSection';
import DiscountSection from 'components/forJobSeekers/CardItems/OrganizationDiscountCard/DiscountSection';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { useRouter } from 'next/router';
import Link from 'next/link';

const { Meta } = Card;

const OrganizationDiscountCard = (props) => {
  const {
    action,
    heading,
    address,
    distance,
    discounts = [],
    organizationName,
  } = props;

  const cardHref = `/discounts-for-childcare-workers/${
    action?.title
  }?${qs.stringify(
    { params: action?.params },
    {
      arrayFormat: 'brackets',
    },
  )}`;

  const route = useRouter();
  const dispatch = useDispatch();

  const handleClickItem = () => {
    const { eventName, eventData } = action?.loggingData;

    route.push({
      pathname: `/discounts-for-childcare-workers/${action?.title}`,
      query: qs.stringify(
        { params: action?.params },
        {
          arrayFormat: 'brackets',
        },
      ),
    });
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };

  return (
    <Link href={cardHref} onClick={handleClickItem} passHref>
      <OrganizationDiscountCardStyles>
        <Card
          hoverable
          bordered={false}
          className="shadow"
          cover={<JobCardCover fullWidth heading={heading} />}
        >
          <Meta title={organizationName} className="job-card__meta" />
          <div className="job-card__content">
            <div className="job-card__info-item">
              <AddressSection distance={distance} address={address} />
            </div>
            <DiscountSection discounts={discounts} />
          </div>
        </Card>
      </OrganizationDiscountCardStyles>
    </Link>
  );
};

OrganizationDiscountCard.propTypes = {
  action: PropTypes.object,
  heading: PropTypes.object,
  discounts: PropTypes.object,
  distance: PropTypes.string,
  organizationName: PropTypes.string,
  address: PropTypes.string,
};

export default OrganizationDiscountCard;
