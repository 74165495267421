import styled from 'styled-components';

export const CardHeadingTagWrapperStyles = styled.div`
  padding: 6px 8px;
  background: var(--text-green);
  display: inline-block;
  border-radius: 8px;
  color: white;
  margin: 11px 55px 10px 0;
`;
