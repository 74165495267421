import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Image from 'components/Image';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import Link from 'next/link';
import { CarouselHeaderStyles, CarouselTitle } from './styles';

const CarouselHeader = ({
  headline,
  subHeadline,
  icon,
  onViewAllPress,
  href,
  btnText = 'button.viewAll',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onViewJobDetail = () => {
    const { eventName, eventData } = onViewAllPress?.loggingData;
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };

  const renderViewAll = () => {
    if (href && onViewAllPress) {
      return (
        <Link href={href} passHref>
          <a href={href} onClick={onViewJobDetail}>
            {t(btnText)}
          </a>
        </Link>
      );
    }

    return null;
  };

  return (
    <CarouselHeaderStyles>
      <div className="flex-center-between">
        <div className="flex-center">
          <CarouselTitle
            className="feature-title__title"
            hasVerticalLine={headline?.verticalLine}
          >
            {headline?.content}
          </CarouselTitle>
          {icon && (
            <Image
              src={icon}
              alt="feature icon"
              isPublic
              className="headline-icon"
            />
          )}
        </div>

        {renderViewAll()}
      </div>
      {subHeadline && (
        <div className="feature-title__subTitle">{subHeadline}</div>
      )}
    </CarouselHeaderStyles>
  );
};

CarouselHeader.propTypes = {
  subHeadline: PropTypes.string,
  headline: PropTypes.object,
  icon: PropTypes.string,
  onViewAllPress: PropTypes.object,
  btnText: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CarouselHeader;
