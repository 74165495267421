import React from 'react';
import { RenderHeaderItems } from 'components/forJobSeekers/Helpers/RenderComponent';
import CoverImage from './CoverImage';

const AdsCardCover = ({ heading, thumbnails }: AdsCardForJobCoverProps) => {
  const { topLeft, topRight } = heading;
  return (
    <div>
      <div className="position-relative">
        <CoverImage thumbnails={thumbnails} />
        <div>{RenderHeaderItems(topRight)}</div>
        <div>{RenderHeaderItems(topLeft)}</div>
      </div>
    </div>
  );
};

export default AdsCardCover;
