import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';

const NQSRating = ({ url, alt = 'kindicare' }) => (
  <Image className="job-card-cover__NQSRType-img" alt={alt} src={url} />
);

NQSRating.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
};

export default NQSRating;
