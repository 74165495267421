import React from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import * as propTypes from 'prop-types';

const OrganizationLogo = (props) => {
  const { url, size = 48, alt = 'company-logo' } = props;
  return <Avatar alt={alt} src={url} size={size} icon={<PictureOutlined />} />;
};
OrganizationLogo.propTypes = {
  url: propTypes.string,
  alt: propTypes.string,
  size: propTypes.number,
};

export default OrganizationLogo;
