import styled from 'styled-components';

export const JobMatchCardStyles = styled.div`
  background: linear-gradient(0deg, #112b2b 0%, rgba(80, 106, 106, 0) 50%),
    url(${({ featuredPhoto }) => `"${featuredPhoto}"`}) center no-repeat;
  border-radius: 16px;
  background-size: cover;
`;

export const CardJobMatchingStyles = styled.div`
  padding: 12px;
  height: 296px;
`;

export const OrganizationLogoWrapper = styled.div`
  background-color: var(--text-white);
  padding: 2px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
`;
