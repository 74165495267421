import React from 'react';
import { useTranslation } from 'react-i18next';
import { getYoutubeEmbedURL } from 'utils/tools';
import Slide from './Slide';
import { ImageDefaultStyle } from './styles';

interface UploadMediaProps {
  type?: string;
  thumbnail?: string;
  source?: string;
}

interface CoverImageProps {
  thumbnails: AdThumbnails[];
}

const CoverImage = ({ thumbnails }: CoverImageProps) => {
  const { t } = useTranslation();

  const renderMediaItems = (mediaItem: UploadMediaProps) => {
    const youtubeLink = getYoutubeEmbedURL(mediaItem?.thumbnail);
    if (mediaItem.source === 'youtube' || youtubeLink) {
      return (
        <iframe
          title="iframe-video-item"
          frameBorder="0"
          className="iframe-video object-cover w-full h-full"
          marginWidth={0}
          marginHeight={0}
          allowFullScreen
          src={youtubeLink}
        />
      );
    }
    return (
      <video
        controls
        className="iframe-video object-cover w-full rounded-top-left-right-radius-8 "
      >
        <source src={mediaItem?.thumbnail} type="video/mp4" />
        {t('error.notSupportVideo')}
        <track src="" kind="captions" srcLang="en" label="english_captions" />
      </video>
    );
  };

  const renderCoverImage = () => {
    if (thumbnails?.length === 1) {
      if (thumbnails?.[0]?.type === 'image') {
        return (
          <div className="flex-center h-full rounded-top-left-right-radius-8">
            {thumbnails?.length && thumbnails[0]?.thumbnail && (
              <img
                className="w-full h-full rounded-top-left-right-radius-8 object-cover"
                src={thumbnails[0]?.thumbnail}
                alt="ad-thumbnail"
              />
            )}
          </div>
        );
      }
      return (
        <div className="flex-center h-full rounded-top-left-right-radius-8">
          {thumbnails?.length &&
            thumbnails[0]?.thumbnail &&
            renderMediaItems(thumbnails[0])}
        </div>
      );
    }
    return (
      <div>
        <Slide thumbnails={thumbnails} />
      </div>
    );
  };

  return <ImageDefaultStyle>{renderCoverImage()}</ImageDefaultStyle>;
};

export default CoverImage;
