import dayjs from 'utils/dayUtils';

export const validateSelectEndDateRangePicker = (current, form) =>
  (current &&
    dayjs(current).isBefore(form.getFieldValue('startDate'), 'month')) ||
  dayjs(current).isAfter(dayjs());

export const validateSelectStartDateRangePicker = (current) =>
  current && dayjs(current).isAfter(dayjs());

export const formatDatePicker = (
  date,
  timeUnit = 'month',
  formatPattern = 'YYYY-MM-DD',
) => (date ? dayjs(date).startOf(timeUnit).format(formatPattern) : null);

export const convertPostedDate = (date) => {
  const numberOfDays = date.match(/(\d)+/gim)?.[0];
  const dateUnit = numberOfDays > 1 ? 'days ' : 'day ';
  const dateRegexp = /^(\d)+d\s/gim;
  return dateRegexp.test(date)
    ? date.replace(dateRegexp, `${numberOfDays} ${dateUnit}`)
    : date;
};

export const convertDistanceFromSlider = (sliderValue) =>
  sliderValue?.length === 2 ? sliderValue?.[0] : sliderValue;
