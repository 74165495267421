import React from 'react';
import PropTypes from 'prop-types';
import CarouselHeader from '@uikit/carousels/JobCarousel/CarouselHeader';
import RenderComponent from 'components/forJobSeekers/Helpers/RenderComponent';

const SectionHeader = ({
  headline,
  subHeadline,
  iconUrl,
  viewAllHref,
  onViewAllPress,
}) => (
  <CarouselHeader
    headline={headline}
    subHeadline={subHeadline}
    icon={iconUrl}
    onViewAllPress={onViewAllPress}
    href={viewAllHref}
  />
);

const SectionContent = ({ content, settings, viewAllHref }) =>
  content ? RenderComponent({ ...content, settings, viewAllHref }) : null;

const HomepageSection = (props) => (
  <>
    <SectionHeader {...props} />
    <SectionContent {...props} />
  </>
);

SectionHeader.propTypes = {
  headline: PropTypes.object,
  iconUrl: PropTypes.string,
  subHeadline: PropTypes.string,
  onViewAllPress: PropTypes.object,
  viewAllHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SectionContent.propTypes = {
  content: PropTypes.object,
  settings: PropTypes.object,
  viewAllHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default HomepageSection;
