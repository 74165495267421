import styled from 'styled-components';

export const HomepageJobMatchLockStyles = styled.div`
  border-radius: 40px !important;
  background: var(--text-green-white);
  padding: 48px 48px 0px 48px;
  .home-banner {
    &__title {
      font-weight: bold;
      font-size: ${({ isStyleDetailPage }) =>
        isStyleDetailPage ? '24px' : '32px'};
      line-height: 40px;
      color: var(--color-pastel-green);
      -webkit-background-clip: text;
    }
    &__desc {
      font-size: ${({ isStyleDetailPage }) =>
        isStyleDetailPage ? '14px' : '18px'};
      line-height: 32px;
      max-width: 600px;
      color: var(--color-pastel-green);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &__button {
      border-radius: 12px;
      background-color: var(--text-green);
      color: var(--text-green-white);
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      display: flex;
      flex-direction: row;
      align-items: center;

      :hover {
        border-color: (var(--text-green)) !important;
      }
    }
    &__animation {
      animation: pulse 1s infinite;
      animation-timing-function: linear;
    }
  }

  .banner-img {
    width: 350px;
    & > .ant-image {
      position: relative;
      right: 150px;
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 20px 20px 4px 20px;
    border-radius: 32px !important;

    .home-banner {
      &__title {
        font-size: 28px;
        line-height: 38px;
        text-align: left;
      }
      &__desc {
        font-size: 14px;
        line-height: 28px;
        margin-top: 16px;
      }
      &__button {
        font-size: 14px;
      }
    }
  }
`;
