import React from 'react';
import KindiRating from '@uikit/commons/KindiRating';
import PropTypes from 'prop-types';

const KindiCareRating = ({ url, kindiCareRating }) => (
  <div>
    <KindiRating
      className="block-btn job-card-cover__rating-btn"
      kindiCareRatingImage={url}
      kindiCareRating={kindiCareRating}
      width={35}
      height={36}
      imgClassName="!rounded-xl"
    />
  </div>
);

KindiCareRating.propTypes = {
  url: PropTypes.string,
  kindiCareRating: PropTypes.any,
};

export default KindiCareRating;
