import styled from 'styled-components';

export const AdsCardStyles = styled.div`
  max-width: 100%;
  .ant-card-body {
    padding: 0 !important;
  }

  .ant-card-meta-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }
  .ant-card-meta-description {
    color: var(--text-grey-l1);
    font-size: 14px;
  }
`;
export const CallingActionStyle = styled.div`
  color: ${({ isParentApp }) =>
    isParentApp ? 'var(--primary)' : 'var(--text-green)'};
  background: ${({ isParentApp }) =>
    isParentApp ? 'var(--bg-pastel-pink)' : 'var(--bg-pastel-green-l100)'};
  padding: 4px 12px;
  .ant-card-body {
    padding: 0;
  }
`;
export const SponsoredTextStyle = styled.div`
  top: 12px;
  left: 12px;
  border-radius: 6px;
  opacity: 0.6;
`;
export const IconDownloadStyle = styled.div`
  top: 12px;
  right: 12px;
  border-radius: 6px;
  opacity: 0.6;
`;

export const CarouselStyle = styled.div`
  position: relative;
  .ant-carousel {
    .slick-slider {
      margin: 0;
    }
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: var(--text-grey-l1);
  }
  .ant-carousel .slick-dots {
    z-index: 1;
    bottom: 16px;
    li {
      width: 8px;
      button {
        background: var(--text-white);
        opacity: 1;
        border-radius: 100px;
        width: 5px;
        height: 5px;
      }
    }
  }
`;

export const ArrowRightStyle = styled.div`
  width: 25px;
  height: 25px;
`;

export const ImageDefaultStyle = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .iframe-video {
    aspect-ratio: 16/9;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
