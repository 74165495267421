import { Button, Card, Tooltip, Typography } from 'antd';
import CoverImageRatio from '@uikit/items/CoverImageRatio';
import Link from '@uikit/commons/Link';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { ArticleCardStyles } from './styles';

const { Meta } = Card;

interface Params {
  slug?: string;
  categoryUrl?: string;
}

interface Props {
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
  thumbnail?: string;
  tag?: string;
  subCategory?: string;
  heading?: string;
  subHeading?: string;
  action?: {
    loggingData?: any;
    link?: string;
    params?: Params;
  };
}

const ArticleCard = ({ fullWidth = true, ...props }: Props) => {
  const { thumbnail, tag, subCategory, heading, subHeading, action } = props;
  const { slug, categoryUrl } = action?.params || {};
  const href =
    action?.link || `/${categoryUrl ?? 'tips-for-childcare-workers'}/${slug}`;

  const dispatch = useDispatch();
  const { eventName, eventData } = action?.loggingData;
  const handleClickItem = () => {
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };
  return (
    <ArticleCardStyles onClick={handleClickItem}>
      <Link href={href} externalUrl={action?.link} target="_blank">
        <Card
          hoverable
          cover={
            <div className="article-item__cover-wrapper">
              <CoverImageRatio
                fullWidth={fullWidth}
                src={thumbnail}
                height={310}
              />
              <Button className="article-item__sponsored">{tag}</Button>
            </div>
          }
        >
          <div className="title text-uppercase fw-bold mb-8 size-12">
            {subCategory}
          </div>
          <Meta className="mb-10" title={heading} />
          <Tooltip placement="bottom" title={subHeading}>
            <Typography.Text className="ellipsis-1-t read-content antialiased">
              {subHeading}
            </Typography.Text>
          </Tooltip>
        </Card>
      </Link>
    </ArticleCardStyles>
  );
};

export default ArticleCard;
