import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { RenderHeaderItems } from 'components/forJobSeekers/Helpers/RenderComponent';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import ActionWrapperCardItem from 'components/forJobSeekers/CardItems/JobCard/ActionWrapperCardItem';
import {
  CardJobMatchingStyles,
  JobMatchCardStyles,
  OrganizationLogoWrapper,
} from './styles';

const JobMatchCard = (props = {}) => {
  const {
    organizationLogo,
    jobTitle,
    salary,
    heading = {},
    id,
    slug,
    state,
    suburb,
    company,
    postcode,
    brand,
    centre,
    action,
  } = props;
  const { topLeft, featuredPhoto } = heading;

  const jobMatchDetail = {
    id,
    slug,
    state,
    suburb,
    company,
    postcode,
    brand,
    centre,
  };
  const dispatch = useDispatch();
  const { eventName, eventData } = action?.loggingData;
  const handleClickItem = () => {
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };
  return (
    <JobMatchCardStyles
      featuredPhoto={featuredPhoto}
      className="pointer"
      onClick={handleClickItem}
    >
      <ActionWrapperCardItem isLink item={jobMatchDetail}>
        <CardJobMatchingStyles className="d-flex column space-between">
          <div>{RenderHeaderItems(topLeft)}</div>
          <div>
            {organizationLogo && (
              <OrganizationLogoWrapper>
                <Avatar size={32} src={organizationLogo} />
              </OrganizationLogoWrapper>
            )}
            <p className="size-16 text-white fw-700">{jobTitle}</p>
            <p className="size-14 text-white text-style fw-700">{`${salary}`}</p>
          </div>
        </CardJobMatchingStyles>
      </ActionWrapperCardItem>
    </JobMatchCardStyles>
  );
};

JobMatchCard.propTypes = {
  organizationLogo: PropTypes.string,
  jobTitle: PropTypes.string,
  salary: PropTypes.string,
  heading: PropTypes.object,
  id: PropTypes.string,
  slug: PropTypes.string,
  state: PropTypes.string,
  suburb: PropTypes.string,
  company: PropTypes.string,
  postcode: PropTypes.string,
  brand: PropTypes.string,
  centre: PropTypes.string,
  action: PropTypes.object,
};

export default JobMatchCard;
