import styled from 'styled-components';

export const MatchingScoreWrapper = styled.div`
  .ant-progress-circle .ant-progress-inner {
    background-color: var(--bg-green);
  }
  .ant-progress-circle .ant-progress-text {
    color: var(--text-white);
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: var(--text-white);
    stroke-width: 8;
  }
  .ant-progress-circle-trail {
    stroke-width: 8;
    opacity: 0.7;
  }
  .progress-style {
    background-color: var(--bg-green);
    padding: 2px;
    border-radius: 50%;
  }
`;
