import React from 'react';
import { CardHeadingTagWrapperStyles } from 'components/forJobSeekers/CardItems/JobCard/CardHeadingTag/styles';
import PropTypes from 'prop-types';

const CardHeadingTag = ({ value }) => (
  <CardHeadingTagWrapperStyles>
    <span>{value}</span>
  </CardHeadingTagWrapperStyles>
);

CardHeadingTag.propTypes = {
  value: PropTypes.string,
};

export default CardHeadingTag;
