import styled from 'styled-components';

export const JobCardStyles = styled.div`
  width: 100%;
  max-width: 100%;
  border: ${(props) =>
    props?.isShowBorder
      ? '1px solid var(--text-green)'
      : '1px solid transparent'};
  border-radius: 12px;

  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-card-meta-description {
    color: var(--text-black-neutral);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .job-card {
    &__content {
      position: relative;
    }
    &__info-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      line-height: 16px;
      &:first-child {
        margin-top: 20px;
      }
      &:not(:first-child) {
        padding: 15px 0 0;
      }
      .text-highlight {
        color: var(--color-pastel-green);
      }
      .anticon {
        margin-right: 7px;
        color: var(--color-green);
      }
      .centre-address-distance__address {
        color: var(--black-neutral);
      }
      .centre-address-distance__value > span {
        font-size: 10px;
        color: var(--color-gray-neutral) !important;
        padding: 0 2px;
      }
      .ant-tag {
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        .anticon {
          margin-right: 7px;
          color: var(--black-neutral);
        }
      }
      .salary {
        font-weight: bold;
        color: var(--color-pastel-green);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .time {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        color: var(--text-black-l8);
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .job-card {
      &__info-item {
        &:last-child > .posted-time {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`;

export const StatusStyle = styled.div`
  color: ${(props) => props.color || 'var(--text-grey-l1)'};
  background-color: ${(props) =>
    props.backgroundColor || 'var(--text-grey-l1)'};
  border-radius: ${(props) => `${props.borderRadius}px` || '8px'};
  border: ${(props) =>
    props.borderColor
      ? `1px solid ${props.borderColor}`
      : '1px solid var(--text-grey-l1)'};
  padding: 2px 8px;
  font-size: 12px;
`;

export const TitleStyle = styled.span`
  max-width: ${(props) => (props?.hasJobStatus ? '80%' : '100%')}; ;
`;
