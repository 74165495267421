import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getSelectedJob = (state) => state.jobCompare.selectedJobs;
const getInitialSlide = (state) => state.jobCompare.initialSlide;
const getJobRequirements = (state) => state.jobCompare.jobRequirements;

export const getJobCompareSelector = createDraftSafeSelector(
  [getSelectedJob],
  (data) => data,
);

export const getInitialSlideJobCompareSelector = createDraftSafeSelector(
  [getInitialSlide],
  (data) => data,
);

export const getJobRequirementsSelector = createDraftSafeSelector(
  [getJobRequirements],
  (data) => data,
);
