import React from 'react';
import { Image } from 'antd';
import PropTypes from 'prop-types';

const DiscountSection = ({ discounts }) => (
  <>
    {discounts?.length ? (
      <>
        {discounts.map((discount, index) => {
          const discountValue = discount?.text.split('%')[0];
          return (
            <>
              {discountValue !== '0' ? (
                <div key={index} className="job-card__info-item">
                  <Image preview={false} src={discount?.iconUrl} width={15} />
                  <span className="discount">{discount?.text}</span>
                </div>
              ) : null}
            </>
          );
        })}
      </>
    ) : null}
  </>
);

DiscountSection.propTypes = {
  discounts: PropTypes.array,
};
export default DiscountSection;
