import styled from 'styled-components';

export const OrganizationDiscountCardStyles = styled.a`
  width: 100%;
  max-width: 100%;

  .ant-card-body {
    min-height: 160px;
  }

  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-card-meta-description {
    color: var(--text-black-neutral);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .job-card {
    &__meta {
      margin-top: 10px;
    }
    &__info-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      line-height: 16px;
      &:first-child {
        margin-top: 20px;
      }
      &:not(:first-child) {
        padding: 15px 0 0;
      }
      .text-highlight {
        color: var(--color-pastel-green);
      }
      .anticon {
        margin-right: 7px;
        color: var(--color-green);
      }
      .centre-address-distance__address {
        color: var(--black-neutral);
      }
      .centre-address-distance__value > span {
        font-size: 10px;
        color: var(--color-gray-neutral) !important;
        padding: 0 2px;
      }
      .ant-tag {
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        .anticon {
          margin-right: 7px;
          color: var(--black-neutral);
        }
      }
      .discount {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .job-card {
      &__info-item {
        &:last-child > .posted-time {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`;
