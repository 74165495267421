import React, { ReactNode } from 'react';
import { DESTINATION_TYPE } from 'configs/constants';
import Link from 'next/link';
import { isEmpty } from 'lodash-es';

interface DestinationActionProp {
  bottomRight: BottomRight;
  children: ReactNode;
}

const DestinationAction = ({
  bottomRight,
  children,
}: DestinationActionProp) => {
  const { type, value } = bottomRight;

  const renderDestinationValue = () => {
    switch (type) {
      case DESTINATION_TYPE.link:
        return value;
      case DESTINATION_TYPE.phoneNumber:
        return `tel:${value}`;
      case DESTINATION_TYPE.email:
        return `mailto:${value}`;
      default:
        return null;
    }
  };
  return (
    <>
      {isEmpty(value) ? (
        <div className="h-full">{children}</div>
      ) : (
        <Link href={renderDestinationValue()} scroll={false}>
          <a
            target={type === DESTINATION_TYPE.link ? '_blank' : '_self'}
            className="size-12"
          >
            {children}
          </a>
        </Link>
      )}
    </>
  );
};

export default DestinationAction;
