import React, { useRef } from 'react';
import { Carousel } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { getYoutubeEmbedURL } from 'utils/tools';
import i18next from 'i18next';
import { ArrowRightStyle, CarouselStyle, ImageDefaultStyle } from './styles';

interface UploadMediaProps {
  type?: string;
  thumbnail?: string;
  source?: string;
}

interface SlideProps {
  thumbnails: UploadMediaProps[] | number[];
}

const Slide = ({ thumbnails }: SlideProps) => {
  const carouselRef = useRef<CarouselRef>();

  const handleNextSlide = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    carouselRef.current?.next();
  };

  const renderItem = (item: UploadMediaProps) => {
    if (item.type?.includes('image')) {
      return (
        <img
          className="w-full h-full pt-12 rounded-top-left-right-radius-8 object-cover"
          src={item?.thumbnail}
          alt="ad-thumbnail"
        />
      );
    }
    if (item.type?.includes('video')) {
      const youtubeLink = getYoutubeEmbedURL(item?.thumbnail);
      if (youtubeLink) {
        return (
          <iframe
            title="iframe-video-item"
            frameBorder="0"
            className="iframe-video object-cover"
            marginWidth={0}
            marginHeight={0}
            allowFullScreen
            src={youtubeLink}
          />
        );
      }
      return (
        <video controls className="iframe-video object-cover">
          <source src={item.thumbnail} type="video/mp4" />
          {i18next.t('error.notSupportVideo')}
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </video>
      );
    }
    return null;
  };

  return (
    <CarouselStyle>
      <Carousel className="h-full" ref={carouselRef}>
        {thumbnails?.map((item, index) => (
          <div key={index}>
            <ImageDefaultStyle>
              {item?.thumbnail && renderItem(item)}
            </ImageDefaultStyle>
          </div>
        ))}
      </Carousel>

      <div className="flex-center-end position-absolute top-1/2 right-0 pr-3 rounded-top-left-right-radius-8">
        <ArrowRightStyle
          className="flex-center rounded-full bg-white cursor-pointer"
          onClick={handleNextSlide}
          role="button"
        >
          <ArrowRightOutlined className="size-11" color="#38465C" />
        </ArrowRightStyle>
      </div>
    </CarouselStyle>
  );
};

export default Slide;
