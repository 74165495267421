import { useTranslation } from 'react-i18next';
import { Divider, notification, Row } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import {
  CopyTwoToneIcon,
  EmbedIcon,
  FacebookTwoToneIcon,
  MailTwoToneIcon,
  TwitterLogoIcon,
} from '@uikit/commons/SVGIcons';
import { SHARE_EVENTS_PLATFORM } from 'configs/constants';
import { getCurrentUserSelector } from '@redux/auth/selectors';
import { useRouter } from 'next/router';
import { ICLinkedInCircle } from '@uikit/commons/SVGIcons/icons/svg-social';
import { StyledModal } from './styles';

interface SocialShareModalProps {
  centreId?: string;
  linkCentreDetail?: string;
  titleModal?: string;
  enableLinkedin?: boolean;
  shareLink?: string;
  data?: TrackingEvent;
  isShowEmbed?: boolean;
  handleClickEmbed?: () => void;
}

const SocialShareModal = (
  {
    centreId,
    linkCentreDetail,
    titleModal,
    enableLinkedin,
    shareLink,
    data,
    isShowEmbed,
    handleClickEmbed,
  }: SocialShareModalProps,
  ref,
) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const [isDisabledCopyLink, setIsDisabledCopyLink] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState(t('socialShare.copyLink'));
  const [visible, setVisible] = useState(false);

  const isOnSubsidyPage = pathname === '/child-care-subsidy-calculator';

  const { id: userId } = useSelector(getCurrentUserSelector);

  const href: string | string[] = shareLink ||
    linkCentreDetail || [
      typeof window !== 'undefined' && window.location.href
        ? window.location.href
        : '',
    ];

  const fallbackCopyToClipboard = (string) => {
    if (!document) {
      return;
    }
    const el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.focus();
    el.select();
    try {
      const isSuccess = document.execCommand('copy');
      isSuccess &&
        notification.success({
          message: t('socialShare.copySuccess'),
          duration: 2,
        });
    } catch (err) {
      notification.warning({
        message: t('socialShare.copyFail'),
      });
    }
    document.body.removeChild(el);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(href as string);
      notification.success({
        message: t('socialShare.copySuccess'),
        duration: 2,
      });

      let eventPayload;

      if (data) {
        eventPayload = {
          eventName: data?.eventName,
          eventData: {
            ...data?.eventData,
            shareVia: SHARE_EVENTS_PLATFORM.COPYLINK,
          },
        };
      }

      if (centreId) {
        eventPayload = {
          eventName: EVENT_TRACKING.centreShared,
          eventData: {
            centreId,
            platform: SHARE_EVENTS_PLATFORM.COPYLINK,
            userId,
          },
        };
      }

      if (isOnSubsidyPage) {
        eventPayload = {
          eventName: EVENT_TRACKING.SUBSIDY_CALCULATOR.subsidyCalculatorShare,
          eventData: {
            shareVia: SHARE_EVENTS_PLATFORM.COPYLINK,
          },
        };
      }

      dispatch(trackingEvent(eventPayload));

      setCopyLinkText(t('socialShare.copiedLink'));
      setIsDisabledCopyLink(true);

      setTimeout(() => {
        setCopyLinkText(t('socialShare.copyLink'));
        setIsDisabledCopyLink(false);
      }, 3000);
    } catch (err) {
      fallbackCopyToClipboard(href);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const onCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(false);
  };

  const trackingEventShare = (platform = SHARE_EVENTS_PLATFORM.FACEBOOK) => {
    if (centreId) {
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.centreShared,
          eventData: {
            centreId,
            platform,
          },
        }),
      );
    } else if (data) {
      const eventPayload: TrackingEvent = {
        eventName: data?.eventName,
        eventData: {
          ...data?.eventData,
          shareVia: platform,
        },
      };
      dispatch(trackingEvent(eventPayload));
    } else if (isOnSubsidyPage) {
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.SUBSIDY_CALCULATOR.subsidyCalculatorShare,
          eventData: {
            shareVia: platform,
          },
        }),
      );
    }
  };

  return (
    <StyledModal
      onCancel={onCancel}
      visible={visible}
      title={titleModal ?? t('socialShare.sharePopupTitle')}
      centered
      footer={null}
    >
      <Row
        className="d-flex column xs:px-6 px-16 "
        onClick={(e) => e.stopPropagation()}
      >
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            href as string,
          )}`}
          target="_blank"
          className="py-3.5 d-flex items-center fw-600 size-18"
          rel="noreferrer"
          onClick={() => trackingEventShare(SHARE_EVENTS_PLATFORM.FACEBOOK)}
        >
          <FacebookTwoToneIcon className="mr-24" />
          {t('socialShare.facebook')}
        </a>
        <Divider />
        <a
          href={`mailto:?subject=Kindicare&body=${encodeURIComponent(
            href as string,
          )}`}
          className="py-3.5 d-flex items-center fw-600 size-18"
          onClick={() => trackingEventShare(SHARE_EVENTS_PLATFORM.GMAIL)}
        >
          <MailTwoToneIcon className="mr-24" />
          {t('socialShare.email')}
        </a>
        <Divider />

        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            href as string,
          )}`}
          target="_blank"
          className="py-3.5 d-flex items-center fw-600 size-18"
          rel="noreferrer"
          onClick={() => trackingEventShare(SHARE_EVENTS_PLATFORM.TWITTER)}
        >
          <TwitterLogoIcon className="mr-24  size-30" />
          {t('socialShare.twitter')}
        </a>
        <Divider />
        {enableLinkedin && (
          <>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                href as string,
              )}`}
              target="_blank"
              className="py-3.5 d-flex items-center fw-600 size-18"
              rel="noreferrer"
              onClick={() => trackingEventShare(SHARE_EVENTS_PLATFORM.LINKEDIN)}
            >
              <ICLinkedInCircle className="mr-24 size-20" />
              {t('socialShare.linkedIn')}
            </a>
            <Divider />
          </>
        )}

        <a
          role="presentation"
          onClick={(e) =>
            isDisabledCopyLink ? e.preventDefault() : handleCopyToClipboard()
          }
          className={` py-3.5 d-flex items-center fw-600 size-18 ${
            isDisabledCopyLink ? 'not-allowed coppied' : 'copy'
          }`}
        >
          <CopyTwoToneIcon className="mr-20" />
          {copyLinkText}
        </a>
        <Divider />
        {isShowEmbed && (
          <div
            className="d-flex items-center pointer py-3.5"
            onClick={() => {
              setVisible(false);
              handleClickEmbed();
            }}
            role="presentation"
          >
            <EmbedIcon className="text-pink-primary size-32 mr-20" />
            <span className="size-18 fw-600 ">
              {t('subsidyCalculator.embed')}
            </span>
          </div>
        )}
      </Row>
    </StyledModal>
  );
};

export default forwardRef(SocialShareModal);
