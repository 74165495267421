import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { HeartFilledIcon, HeartOutlineIcon } from '@uikit/commons/SVGIcons';
import {
  addFavoriteJobs,
  deleteFavoriteJobs,
} from '@redux/jobsModule/jobDetail/actions';
import { debounce } from 'lodash-es';
import { getCurrentLocation } from '@redux/config/selectors';
import { useLazyGetAllJobForSeekersHomepageQuery } from '@redux/jobsModule/jobSeeker';
import { DEFAULT_GEO_LOCATION } from 'configs/constants';
import { useRouter } from 'next/router';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';
import { useEffect, useState } from 'react';

const FavoriteButton = ({ postId, handleAfterFavorite, ...props }) => {
  const dispatch = useDispatch();
  const { asPath } = useRouter();
  const [loading, setLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(props.isFavorite);
  const {
    geoLocation: { latitude: currentLatitude, longitude: currentLongitude },
  } = useSelector(getCurrentLocation);
  const isUserOnJobHomepage =
    asPath === FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH ||
    asPath === `${FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH}?t=browseJobs`;
  const [refetchLazy] = useLazyGetAllJobForSeekersHomepageQuery();
  const refetchFavoriteJobs = debounce((params) => refetchLazy(params), 2000);

  useEffect(() => {
    setIsFavorite(props.isFavorite);
  }, [props.isFavorite]);

  const favoriteJob = async (e) => {
    e?.preventDefault();
    setLoading(true);
    if (isUserOnJobHomepage) {
      refetchFavoriteJobs({
        data: {
          location: {
            latitude: currentLatitude || DEFAULT_GEO_LOCATION.latitude,
            longitude: currentLongitude || DEFAULT_GEO_LOCATION.longitude,
          },
        },
        options: {
          isDefaultOrderBy: false,
          isParamsForAPIV2: true,
        },
      });
    }
    if (isFavorite) {
      setIsFavorite(false);
      await dispatch(deleteFavoriteJobs(postId));
    } else {
      await dispatch(addFavoriteJobs(postId));
      setIsFavorite(true);
    }
    handleAfterFavorite?.(!isFavorite);
    setLoading(false);
  };

  return (
    <RequiredAuthButton
      className="block-btn centre-item__favorite"
      onClick={favoriteJob}
      loading={loading}
      icon={
        isFavorite ? (
          <HeartFilledIcon style={{ color: 'var(--text-green)' }} />
        ) : (
          <HeartOutlineIcon />
        )
      }
      {...props}
    />
  );
};

FavoriteButton.propTypes = {
  postId: PropTypes.string,
  isFavorite: PropTypes.bool,
  favoriteJob: PropTypes.func,
  jobInfo: PropTypes.any,
  handleAfterFavorite: PropTypes.func,
};

export default FavoriteButton;
