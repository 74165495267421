import styled, { css } from 'styled-components';

export const CarouselHeaderStyles = styled.div`
  margin: 50px 0 30px;
  position: relative;

  .feature-title {
    &__title {
      color: #141533;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      word-break: break-word;
    }

    &__subTitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #38465c;
      margin-top: 12px;
    }
  }

  .headline-icon {
    margin-left: 12px;
    width: 24px;
    height: 29px;
    object-fit: cover;
  }

  a {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    white-space: nowrap;
    color: var(--text-green);
  }

  @media only screen and (max-width: 576px) {
    .feature-title {
      &__subTitle {
        font-size: 16px;
      }
      &__title {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .headline-icon {
      width: 20px;
      height: 25px;
    }
  }

  @media only screen and (max-width: 425px) {
    .feature-title {
      &__subTitle {
        font-size: 13px;
      }
      &__title {
        font-size: 18px;
        line-height: 20px;
      }
    }

    a {
      font-size: 14px;
      line-height: 0px;
    }
  }
`;

export const CarouselTitle = styled.h2`
  ${({ hasVerticalLine }) =>
    hasVerticalLine &&
    css`
      --margin-left-vertical-line: 16px;

      position: relative;
      margin-left: var(--margin-left-vertical-line);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(var(--margin-left-vertical-line) * -1);
        width: 5px;
        background-color: var(--text-green);
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    `}
`;
