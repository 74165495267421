import PropTypes from 'prop-types';
import React from 'react';
import CardItem from '@uikit/items/CardItem';
import RenderComponent from 'components/forJobSeekers/Helpers/RenderComponent';
import { JobSlide } from './styles';

const JobCarousel = (props) => {
  const { items, settings = {}, viewAllHref } = props;

  return (
    <div>
      <JobSlide
        isShadowItem
        variableWidth={false}
        className="slide-h-full"
        {...settings}
      >
        {items?.map((item) => (
          <CardItem className="h-full" key={String(item?.id)}>
            {RenderComponent(item, { viewAllHref })}
          </CardItem>
        ))}
      </JobSlide>
    </div>
  );
};

JobCarousel.propTypes = {
  items: PropTypes.array,
  settings: PropTypes.object,
  viewAllHref: PropTypes.object,
};

export default JobCarousel;
