import { Button, Col, Image, Row } from 'antd';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { JobMatchLockLoggingData } from 'types/jobSeeker';
import { HomepageJobMatchLockStyles } from './styles';

interface RequireProfileUpdate {
  button?: {
    value?: string;
  };
  description?: string;
}

interface ButtonProps {
  action?: {
    loggingData?: JobMatchLockLoggingData;
  };
  text?: string;
}

interface Props {
  isAuth?: boolean;
  refetch?: () => void;
  isStyleDetailPage?: boolean;
  requireProfileUpdate?: RequireProfileUpdate;
  icon?: string;
  title?: string;
  subTitle?: string;
  button?: ButtonProps;
  bannerImageUrl?: string;
}

const HomepageJobMatchLock = ({
  isAuth,
  refetch,
  isStyleDetailPage,
  requireProfileUpdate,
  ...bannerData
}: Props) => {
  const { push } = useRouter();
  const { icon, title, subTitle, button, bannerImageUrl } = bannerData;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) refetch();
  }, [isAuth, refetch]);

  const handleClickUpdateProfile = () => {
    const { eventName, eventData } = button?.action?.loggingData;
    push(`${FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH}?t=jobProfile`);
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };

  const textUpdateJobProfileBtn =
    button?.text || requireProfileUpdate?.button?.value;
  const subTitleDesc = subTitle || requireProfileUpdate?.description;

  return (
    <HomepageJobMatchLockStyles isStyleDetailPage={isStyleDetailPage}>
      <Row gutter={80} justify="space-between">
        <Col xs={24} md={24} lg={24} xl={isStyleDetailPage ? 16 : 14}>
          {icon && title && (
            <Row className="d-flex flex-row items-center">
              <Col xs={0} sm={2}>
                <Image
                  width={32}
                  height={32}
                  src={icon}
                  preview={false}
                  className="home-banner__animation"
                />
              </Col>
              <Col xs={24} sm={22}>
                <div className="home-banner__title">{title}</div>
              </Col>
            </Row>
          )}
          <div className="home-banner__desc">{subTitleDesc}</div>
          <Button
            className="home-banner__button mt-20 mb-16"
            onClick={handleClickUpdateProfile}
          >
            {textUpdateJobProfileBtn}
            <Image src="/images/banners/ic-arrow-right.png" preview={false} />
          </Button>
        </Col>
        <Col xs={0} md={24} lg={24} xl={isStyleDetailPage ? 8 : 10}>
          <div className={isStyleDetailPage && 'banner-img'}>
            <Image
              src={bannerImageUrl || '/images/banners/job-provider-banner.png'}
              preview={false}
            />
          </div>
        </Col>
      </Row>
    </HomepageJobMatchLockStyles>
  );
};

export default HomepageJobMatchLock;
