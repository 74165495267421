import ModalCustom from '@uikit/commons/ModalCustom';
import styled from 'styled-components';

export const StyledModal = styled(ModalCustom)`
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }

  .copy {
    color: var(--text-primary);
  }

  .coppied {
    color: var(--color-domain);
  }
`;
