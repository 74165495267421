import React from 'react';
import PropTypes from 'prop-types';

const NoResultsBanner = ({ imageUrl, text }) => (
  <div className="flex-column flex-center my-48">
    <img src={imageUrl} width={235} alt="no results banner" />
    <p className="text-grey-l1 mt-20">{text}</p>
  </div>
);

NoResultsBanner.propTypes = {
  imageUrl: PropTypes.string,
  text: PropTypes.string,
};

export default NoResultsBanner;
