import React from 'react';
import FavoriteButton from 'components/forJobSeekers/Detail/FavoriteButton';
import PropTypes from 'prop-types';

const CardFavoriteButton = ({ data, state: isFavoriteJob }) => (
  <FavoriteButton
    className="block-btn job-card-cover__favorite"
    postId={data?.postId}
    isFavorite={isFavoriteJob}
  />
);

CardFavoriteButton.propTypes = {
  data: PropTypes.object,
  state: PropTypes.bool,
};

export default CardFavoriteButton;
