import styled from 'styled-components';

export const AddressSectionWrapper = styled.div`
  .distance {
    color: var(--color-pastel-green);
  }

  .dot {
    color: var(--color-gray-neutral);
    margin: 0 5px;
  }
`;
