import { Card } from 'antd';
import styled from 'styled-components';

export const FeaturedBusinessCardWapper = styled(Card)`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
  .company-type {
    color: var(--text-grey-l1) !important;
    background-color: var(--bg-grey-l1) !important;
    margin: 5px 0px 12px 0px;
    padding: 4px 10px;
    border-radius: 100px;
  }
  .company-logo {
    border-radius: 100%;
    border: 1px solid var(--border-color-light);
  }
  .company-name {
    color: var(--text-black-l8);
    line-height: 24px;
    margin: 12px 0px 10px 0px;
  }
  .active-job-count {
    border-radius: 6px;
    padding: 4px 10px;
    background-color: var(--bg-light-green);
    color: var(--text-white);
    margin: 12px 0px 5px 0px;
  }
`;
