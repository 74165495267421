import React from 'react';
import JobMatchProgressCircle from 'components/forJobSeekers/CardItems/JobMatchProgressCircle';
import ViewAllCard from 'components/forJobSeekers/CardItems/ViewAllCard';
import FeaturedBusinessCard from 'components/forJobSeekers/CardItems/FeaturedBusinessCard';
import JobCard from 'components/forJobSeekers/CardItems/JobCard';
import OrganizationLogo from 'components/forJobSeekers/CardItems/JobCard/OrganizationLogo';
import KindiCareRating from 'components/forJobSeekers/CardItems/JobCard/KindiCareRating';
import NQSRating from 'components/forJobSeekers/CardItems/JobCard/NQSRating';
import CardFavoriteButton from 'components/forJobSeekers/CardItems/JobCard/CardFavoriteButton';
import JobMatchCard from 'components/forJobSeekers/CardItems/JobMatchCard';
import HomepageJobMatchLock from '@uikit/banners/HomepageJobMatchLock';
import OrganizationDiscountCard from 'components/forJobSeekers/CardItems/OrganizationDiscountCard';
import Carousel from '@uikit/carousels/JobCarousel';
import CardHeadingTag from 'components/forJobSeekers/CardItems/JobCard/CardHeadingTag';
import AdsCard from '@uikit/commons/AdsCard';
import AdsHeadingTag from '@uikit/commons/AdsCard/AdsHeadingTag';
import AdsShareButton from '@uikit/commons/AdsCard/AdsShareButton';
import ArticleCard from 'components/forJobSeekers/CardItems/ArticleCard';
import ListViewDivider from 'components/forJobSeekers/components/ListViewDivider';
import HomepageSection from 'components/forJobSeekers/components/HomepageSection';
import NoResultsBanner from 'components/forJobSeekers/components/NoResultsBanner';

const SupportedComponentItems = Object.freeze({
  ViewAllCard,
  JobMatchProgressCircle,
  FeaturedBusinessCard,
  ArticleCard,
  JobCard,
  OrganizationLogo,
  KindiCareRating,
  NQSRating,
  CardFavoriteButton,
  JobMatchCard,
  HomepageJobMatchLock,
  OrganizationDiscountCard,
  ListViewDivider,
  HomepageSection,
  Carousel,
  NoResultsBanner,
  CardHeadingTag,
  AdsCard,
  AdsShareButton,
  AdsHeadingTag,
});

const RenderComponent = (item = {}, extraProps = {}) => {
  const { _typename, ...propsData } = item;
  const componentItem = SupportedComponentItems[_typename];
  if (!componentItem) {
    return null;
  }
  return React.createElement(componentItem, { ...propsData, ...extraProps });
};

export const RenderHeaderItems = (items = []) =>
  items.map((item) => <div>{RenderComponent(item)}</div>);

export default RenderComponent;
