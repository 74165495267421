import React from 'react';
import { Avatar, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { getLinkJobsByBrand, getLinkJobsByCompany } from 'utils/tools';
import Link from '@uikit/commons/Link';
import { FeaturedBusinessCardWapper } from './styles';

const FeaturedBusinessCard = (props = {}) => {
  const {
    action,
    brand,
    activeJobsCount,
    organizationLogo,
    organizationName,
    organizationType,
  } = props;

  const link = brand
    ? getLinkJobsByBrand(props, {}, action?.params?.filter?.brands?.[0])
    : getLinkJobsByCompany(props, {}, action?.params?.filter?.companies?.[0]);

  return (
    <Link href={link}>
      <FeaturedBusinessCardWapper className="pointer">
        <div className="flex-center column h-full">
          {organizationType && (
            <div className="company-type lh-18 size-12 fw-700">
              {organizationType}
            </div>
          )}
          <div className="company-logo">
            <Avatar size={80} src={organizationLogo} />
          </div>
          <div className="flex-center-between column h-full">
            <div className="company-name text-center size-14 fw-700 flex-center h-full ellipsis-2-t">
              <Tooltip title={organizationName}>{organizationName}</Tooltip>
            </div>
            <div className="active-job-count w-700 size-12">
              {activeJobsCount}
            </div>
          </div>
        </div>
      </FeaturedBusinessCardWapper>
    </Link>
  );
};

FeaturedBusinessCard.propTypes = {
  activeJobsCount: PropTypes.string,
  organizationLogo: PropTypes.string,
  organizationName: PropTypes.string,
  organizationType: PropTypes.string,
  action: PropTypes.object,
  brand: PropTypes.string,
};

export default FeaturedBusinessCard;
