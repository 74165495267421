import PropTypes from 'prop-types';
import Link from '@uikit/commons/Link';
import { getLinkJobDetail } from 'utils/tools';

const ActionWrapperCardItem = ({ isLink, item, children, ...props }) =>
  isLink ? (
    <Link href={getLinkJobDetail(item)}>{children}</Link>
  ) : (
    <div {...props}>{children}</div>
  );

ActionWrapperCardItem.propTypes = {
  isLink: PropTypes.bool,
  item: PropTypes.object,
};

export default ActionWrapperCardItem;
