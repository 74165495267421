import styled from 'styled-components';

export const ArticleCardStyles = styled.div`
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);

  .title {
    color: var(--text-green);
    font-size: 12px;
    line-height: 18px;
  }
  .ant-card {
    height: 100%;
  }
  .ant-card-bordered {
    overflow: hidden;
    border: none;
  }
  .article-item {
    &__cover-wrapper {
      position: relative;
    }
    &__cover {
      height: 307px;
      object-fit: cover;
      width: 100%;
    }
    &__sponsored {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 8px;
      background: var(--border-color-light);
      opacity: 0.7;
      border: none;
      color: var(--text-secondary);
      font-weight: 700;
    }
  }
  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .read-content {
    font-size: 14px;
    line-height: 24px;
    color: var(--text-black-neutral);
  }
`;
