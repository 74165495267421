import React from 'react';
import { JobCardCoverStyles } from 'components/forJobSeekers/CardItems/JobCard/JobCardCover/styles';
import CoverImageRatio from '@uikit/items/CoverImageRatio';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import { RenderHeaderItems } from 'components/forJobSeekers/Helpers/RenderComponent';

const JobCardCover = ({ fullWidth = true, heading = {} }) => {
  const { bottomLeft, bottomRight, topLeft, topRight, featuredPhoto } = heading;
  return (
    <JobCardCoverStyles>
      <div className="job-card-cover__cover-wrapper">
        <CoverImageRatio fullWidth={fullWidth} src={featuredPhoto} />
        <div className="job-card-cover__group-top-right">
          {RenderHeaderItems(topRight)}
        </div>
        <Space
          wrap
          className="job-card-cover__group-top-left"
          key={'top-left-job-card'}
        >
          <div>{RenderHeaderItems(topLeft)}</div>
        </Space>
        <Space
          wrap
          className="job-card-cover__group-bottom-left"
          key={'bottom-left-job-card'}
        >
          <div>{RenderHeaderItems(bottomLeft)}</div>
        </Space>
        <Space
          className="job-card-cover__group-bottom-right"
          key={'bottom-right-job-card'}
        >
          {RenderHeaderItems(bottomRight)}
        </Space>
      </div>
    </JobCardCoverStyles>
  );
};

JobCardCover.propTypes = {
  fullWidth: PropTypes.bool,
  heading: PropTypes.object,
};

export default JobCardCover;
