import React from 'react';
import { SponsoredTextStyle } from './styles';

interface AdsHeadingTag {
  value: string;
}

const AdsHeadingTag = ({ value }: AdsHeadingTag) => (
  <SponsoredTextStyle className="text-gray-white bg-border-light fw-bold border-none size-12 position-absolute p-8">
    {value}
  </SponsoredTextStyle>
);

export default AdsHeadingTag;
