import React, { useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import SocialShareModal from '@uikit/modals/SocialShareModal';
import { useTranslation } from 'react-i18next';
import { DESTINATION_TYPE } from 'configs/constants';
import { IconDownloadStyle } from './styles';

interface ModalRef {
  open: () => void;
}

interface ShareButtonData {
  callingButtonValue: string;
  postId: string;
  type: string;
}

interface AdsShareButtonProps {
  data: ShareButtonData;
  action: {
    loggingData: {
      _typename?: string;
      eventData: object;
      eventName: string;
    };
  };
}

const AdsShareButton = ({ data, action }: AdsShareButtonProps) => {
  const shareModalRef = useRef<ModalRef>();
  const { t } = useTranslation();

  const openShareModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    shareModalRef.current.open();
  };

  return (
    <>
      {data?.type === DESTINATION_TYPE.link && (
        <IconDownloadStyle
          onClick={openShareModal}
          className="text-gray-white bg-border-light position-absolute border-none"
        >
          <UploadOutlined className="size-16 fw-bold p-8" />
        </IconDownloadStyle>
      )}
      <SocialShareModal
        shareLink={data?.callingButtonValue}
        ref={shareModalRef}
        enableLinkedin
        titleModal={t('text.shareThisAd')}
        data={action?.loggingData}
      />
    </>
  );
};

export default AdsShareButton;
