import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Tooltip } from 'antd';
import { i18n } from 'next-i18next';
import Link from 'next/link';
import { PROFILE_URL } from 'components/forJobSeekers/constants';
import { MatchingScoreWrapper } from './styles';

const JobMatchProgressCircle = (props = {}) => {
  const { score, unit, href = PROFILE_URL?.JOB_PROFILE_APPLY_STATUS } = props;

  return (
    <MatchingScoreWrapper className="pointer">
      <Link href={href}>
        <Tooltip
          title={
            score !== '?'
              ? `${score}${unit} ${i18n.t(
                  'forJobSeekers.jobMatching.matchYourProfile',
                )}`
              : ''
          }
        >
          <Progress
            type="circle"
            width={48}
            percent={score}
            format={(percent) => (
              <div>
                {percent}
                {score !== '?' && <sup>{unit}</sup>}
              </div>
            )}
            className="progress-style"
          />
        </Tooltip>
      </Link>
    </MatchingScoreWrapper>
  );
};

JobMatchProgressCircle.propTypes = {
  score: PropTypes.string,
  unit: PropTypes.string,
  href: PropTypes.string,
};

export default JobMatchProgressCircle;
