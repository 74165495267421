import styled from 'styled-components';

export const AddToCompareJobStyle = styled.div`
  padding-right: 5px;

  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid var(--text-green);
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-inner::after {
    border: var(--text-green);
    background-color: var(--text-green);
  }

  .ant-checkbox-checked .ant-checkbox-inner:hover,
  .ant-checkbox-checked::after {
    border: none !important;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-input {
      border: 1px solid var(--text-gray-neutral-l400);
    }

    .ant-checkbox-inner {
      background-color: #fff;
    }
  }

  .ant-checkbox-disabled + span {
    color: var(--text-gray-neutral-l400);
  }
`;
