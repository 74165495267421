import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  addJobToCompare,
  removeJobFromCompare,
} from '@redux/jobsModule/jobCompare/slice';
import PropTypes from 'prop-types';
import { AddToCompareJobStyle } from './styles';

const AddToCompareJob = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    job,
    isAddedToCompare,
    handleAddJobToCompare,
    handleRemoveJobToCompare,
    disabled,
  } = props;

  const handleOnChange = (e) => {
    e.stopPropagation();
    const { checked } = e.target;
    if (checked) {
      if (handleAddJobToCompare) {
        handleAddJobToCompare(job);
        return;
      }
      dispatch(
        addJobToCompare({
          job,
        }),
      );
    } else {
      if (handleRemoveJobToCompare) {
        handleRemoveJobToCompare(job);
        return;
      }
      dispatch(
        removeJobFromCompare({
          id: job.id,
        }),
      );
    }
  };

  return (
    <AddToCompareJobStyle onClick={(e) => e.stopPropagation()}>
      <Checkbox
        className={'checkbox-14'}
        checked={isAddedToCompare}
        onChange={handleOnChange}
        disabled={disabled}
      >
        <Tooltip
          title={
            disabled
              ? t('comparisonJobs.cannotAddCancelledApplicationsToCompare')
              : undefined
          }
          placement={'top'}
        >
          {isAddedToCompare
            ? t('comparison.addedCompare')
            : t('comparison.addCompare')}
        </Tooltip>
      </Checkbox>
    </AddToCompareJobStyle>
  );
};

AddToCompareJob.propTypes = {
  job: PropTypes.object,
  isAddedToCompare: PropTypes.bool,
  handleAddJobToCompare: PropTypes.func,
  handleRemoveJobToCompare: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AddToCompareJob;
