import React from 'react';
import {
  JobCardStyles,
  StatusStyle,
  TitleStyle,
} from 'components/forJobSeekers/CardItems/JobCard/styles';
import { Card, Image, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import JobCardCover from 'components/forJobSeekers/CardItems/JobCard/JobCardCover';
import {
  BriefCaseIcon,
  DollarSquareIcon,
  EducatorDiscountIcon,
  StaffDiscountIcon,
} from '@uikit/commons/SVGIcons';
import {
  EDUCATOR_DISCOUNT,
  STAFF_DISCOUNT,
} from 'components/forJobSeekers/constants';
import DotCircle from '@uikit/commons/DotCircle';
import AddressSection from 'components/forJobSeekers/components/AddressSection';
import { convertPostedDate } from 'components/jobs/utils';
import { useDispatch, useSelector } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { getJobCompareSelector } from '@redux/jobsModule/jobCompare/selector';
import ActionWrapperCardItem from './ActionWrapperCardItem';
import AddToCompareJob from './AddToCompareJob';

const { Meta } = Card;

const JobCard = (props) => {
  const {
    heading,
    jobTitle,
    jobType,
    address,
    distance,
    postedTime,
    salary,
    discount,
    discountType,
    isLink = true,
    organizationName,
    action,
    enableAddJobToCompare = false,
    addedJobList = null,
    handleAddJobToCompare,
    handleRemoveJobToCompare,
    isShowTooltip = false,
  } = props;
  const renderDiscountTypeIcon = () => {
    switch (discountType) {
      case STAFF_DISCOUNT:
        return <StaffDiscountIcon />;
      case EDUCATOR_DISCOUNT:
        return <EducatorDiscountIcon />;
      default:
        return null;
    }
  };

  const jobDetail = {
    id: props?.id,
    slug: props?.slug,
    state: props?.state,
    suburb: props?.suburb,
    company: props?.company,
    postcode: props.postcode,
    brand: props?.brand,
    centre: props?.centre,
    footerComponents: props?.footerComponents,
    statusLabel: props?.statusLabel,
    heading,
    jobTitle,
    jobType,
    address,
    distance,
    postedTime,
    salary,
    discount,
    discountType,
  };
  const jobComparing = useSelector(getJobCompareSelector);
  const selectedJobs = addedJobList ?? jobComparing;
  const isAddedToCompare = selectedJobs
    ? selectedJobs.some((job) => job.id === jobDetail.id)
    : false;

  const dispatch = useDispatch();
  const { eventName, eventData } = action?.loggingData;
  const handleClickItem = () => {
    dispatch(
      trackingEvent({
        eventName,
        eventData,
      }),
    );
  };

  return (
    <JobCardStyles
      isShowBorder={!isLink && isAddedToCompare}
      onClick={handleClickItem}
    >
      <ActionWrapperCardItem
        isLink={isLink}
        item={jobDetail}
        onClick={() =>
          isAddedToCompare
            ? handleRemoveJobToCompare(jobDetail)
            : handleAddJobToCompare(jobDetail)
        }
      >
        <Card
          hoverable
          bordered={false}
          className="shadow"
          cover={<JobCardCover fullWidth heading={heading} />}
        >
          <div className="job-card__content">
            {isShowTooltip ? (
              <div>
                <div className={'fw-bold size-16 d-flex space-between'}>
                  <Tooltip title={jobTitle}>
                    <TitleStyle
                      hasJobStatus={jobDetail?.statusLabel}
                      className={'ellipsis-default mr-8'}
                    >
                      {jobTitle}
                    </TitleStyle>
                  </Tooltip>
                  {jobDetail?.statusLabel && (
                    <StatusStyle
                      backgroundColor={
                        jobDetail?.statusLabel?.style.backgroundColor
                      }
                      color={jobDetail?.statusLabel?.textStyle?.color}
                      borderColor={jobDetail?.statusLabel?.style.borderColor}
                      borderRadius={jobDetail?.statusLabel?.style?.borderRadius}
                    >
                      {jobDetail?.statusLabel?.text}
                    </StatusStyle>
                  )}
                </div>
                <div className={'job-card__info-item'}>
                  <Tooltip title={organizationName}>
                    <span className={'ellipsis-default w-100'}>
                      {organizationName}
                    </span>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <Meta
                title={jobTitle}
                description={organizationName}
                className="job-card__meta"
              />
            )}

            <div className="job-card__info-item">
              <AddressSection distance={distance} address={address} />
            </div>
            <div className="job-card__info-item ">
              <DollarSquareIcon />
              <span className="salary">{salary}</span>
            </div>
            <div className="job-card__info-item">
              {discount && (
                <>
                  <Tooltip title={discountType}>
                    <span>
                      {renderDiscountTypeIcon()}
                      {discount}
                    </span>
                  </Tooltip>
                  <DotCircle color="var(--color-gray-neutral)" size={5} />
                </>
              )}
              <Tag>
                <BriefCaseIcon />
                {jobType}
              </Tag>
              <DotCircle color="var(--color-gray-neutral)" size={5} />
              <span className="posted-time">
                {convertPostedDate(postedTime)}
              </span>
            </div>
            {jobDetail.footerComponents && (
              <div className="job-card__info-item">
                <Image
                  preview={false}
                  src={jobDetail?.footerComponents?.[0]?.iconUrl}
                  width={15}
                />
                <div className="time">
                  {jobDetail?.footerComponents?.[0]?.value}
                </div>
              </div>
            )}
            {enableAddJobToCompare && (
              <div className="job-card__info-item">
                <AddToCompareJob
                  job={jobDetail}
                  isAddedToCompare={isAddedToCompare}
                  handleAddJobToCompare={handleAddJobToCompare}
                  handleRemoveJobToCompare={handleRemoveJobToCompare}
                />
              </div>
            )}
          </div>
        </Card>
      </ActionWrapperCardItem>
    </JobCardStyles>
  );
};

JobCard.propTypes = {
  heading: PropTypes.object,
  discount: PropTypes.string,
  distance: PropTypes.string,
  salary: PropTypes.string,
  organizationName: PropTypes.string,
  jobType: PropTypes.string,
  jobTitle: PropTypes.string,
  postedTime: PropTypes.string,
  discountType: PropTypes.string,
  isLink: PropTypes.bool,
  address: PropTypes.string,
  id: PropTypes.string,
  slug: PropTypes.string,
  state: PropTypes.string,
  suburb: PropTypes.string,
  company: PropTypes.string,
  postcode: PropTypes.string,
  brand: PropTypes.string,
  centre: PropTypes.string,
  action: PropTypes.object,
  enableAddJobToCompare: PropTypes.bool,
  addedJobList: PropTypes.array,
  handleAddJobToCompare: PropTypes.func,
  handleRemoveJobToCompare: PropTypes.func,
  footerComponents: PropTypes.object,
  statusLabel: PropTypes.object,
  isShowTooltip: PropTypes.bool,
};

export default JobCard;
