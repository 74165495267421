import React from 'react';
import { AddressSectionWrapper } from 'components/forJobSeekers/components/AddressSection/styles';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';

const AddressSection = ({ distance, address, className }) => (
  <AddressSectionWrapper className="ellipsis-default d-inline-block items-center">
    <EnvironmentOutlined className={className} />
    <span className="distance">{distance}</span>
    <span className="dot size-11">&#9679;</span>
    <Tooltip title={address}>
      <span className="address">{address}</span>
    </Tooltip>
  </AddressSectionWrapper>
);
AddressSection.propTypes = {
  distance: PropTypes.string,
  address: PropTypes.string,
  className: PropTypes.string,
};

export default AddressSection;
